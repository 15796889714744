import { DateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

import { useReducer } from 'react';

export const actionType = {
  setFilters: 'setFilters',
  setDateUnit: 'setDateUnit',
  setPage: 'setPage',
} as const;

type ActionType = typeof actionType;

export type Action =
  | { type: ActionType['setFilters']; payload: State['filters'] }
  | { type: ActionType['setDateUnit']; payload: State['dateUnit'] };

export type State = {
  filters: {
    facilityTypeIds: number[];
    ownDepartmentIds: number[];
    groupMedicalInstitutionId: number | null;
    labelFilterConditions: MedicalInstitutionLabelFilterConditionSchema[];
  };
  dateUnit: DateUnit;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setFilters:
      return {
        ...state,
        filters: action.payload,
      };
    case actionType.setDateUnit:
      return {
        ...state,
        dateUnit: action.payload,
      };
  }
};

export const useSummaryState = () => {
  const [state, dispatch] = useReducer(reducer, {
    filters: {
      facilityTypeIds: [],
      ownDepartmentIds: [],
      groupMedicalInstitutionId: null,
      labelFilterConditions: [],
    },
    dateUnit: 'year',
  } satisfies State);

  return { state, dispatch };
};
