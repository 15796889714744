import { medicalInstitutionsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetFacilityTypeGroups = () => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey('medicalInstitutionsApi', 'getFacilityTypeGroups'),
    () => medicalInstitutionsApi.getFacilityTypeGroups(),
  );
  if (response.error != null) {
    throwFetchError(response.error);
  }

  return response;
};
