import {
  PostReferralReportAggregationResultsRequest,
  PostReferralReportAggregationResultsRequestFilterConditions,
  PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum,
} from '@/src/api/generated';
import { medicalInstitutionLabel2FilterCondition } from '@/src/utils/report';

import { State } from './reducer';

export const toRequestParameter = (
  filters: State['filters'],
  dateUnit: State['dateUnit'],
): PostReferralReportAggregationResultsRequest => {
  return {
    filterConditions: createFilterConditions(filters),
    groupingConditions: [
      {
        definitionKey: 'referral_date',
        dateGrouping: toDateGrouping(dateUnit),
      },
      { definitionKey: 'one_facility_type_id' },
    ],
  };
};

export const createFilterConditions = (
  filters: State['filters'],
): PostReferralReportAggregationResultsRequestFilterConditions[] =>
  [
    // 次の理由から絞り込み条件には、表示で利用している one_facility_type_id ではなく facility_type_ids を利用していることに注意
    //
    // one_facility_type_id でフィルタをかけると、例えば以下のようなパターンで意図せず絞り込みから漏れてしまうことがある
    // ・「訪問入浴介護」で絞り込みをかけた場合、「訪問介護(id = 3001)」「訪問入浴介護(id = 3002)」の指定がある連携先が漏れてしまう
    // ・これは one_facility_type_id が複数の施設タイプからひとつのみを抽出するため
    filters.facilityTypeIds.length >= 1
      ? ({
          definitionKey: 'facility_type_ids',
          matchingGroups: [{ haveAny: filters.facilityTypeIds }],
        } satisfies PostReferralReportAggregationResultsRequestFilterConditions)
      : undefined,
    // 診療科
    filters.ownDepartmentIds.length >= 1
      ? ({
          definitionKey: 'own_department_id',
          matchingGroups: [{ _in: filters.ownDepartmentIds }],
        } satisfies PostReferralReportAggregationResultsRequestFilterConditions)
      : undefined,
    // 医療機関ラベル
    ...(filters.labelFilterConditions ?? [])
      .map(medicalInstitutionLabel2FilterCondition)
      .map(
        (
          condition,
        ): PostReferralReportAggregationResultsRequestFilterConditions => ({
          definitionKey: condition.item.key,
          matchingGroups: condition.matchingGroups,
        }),
      ),
    // グループ病院
    filters.groupMedicalInstitutionId !== null
      ? ({
          definitionKey: 'tenant_medical_institution_id',
          matchingGroups: [{ _in: [filters.groupMedicalInstitutionId] }],
        } satisfies PostReferralReportAggregationResultsRequestFilterConditions)
      : undefined,
  ].flatMap((condition) => condition ?? []); // undefined除去のためのflatMap

const toDateGrouping = (
  dateUnit: State['dateUnit'],
): PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum => {
  switch (dateUnit) {
    case 'year':
      return PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum.FiscalYear;
    case 'halfYear':
      return PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum.HalfYear;
    case 'month':
      return PostReferralReportAggregationResultsRequestGroupingConditionsDateGroupingEnum.Month;
  }
};
