import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';

import styles from './styles.module.scss';

import React from 'react';

import { State } from '../../reducer';
import {
  facilityTypeToLabel,
  itemTypeToLabel,
  itemTypeToPostfix,
} from '../../selector';
import { TABLE_ROW_TYPES, ITEM_TYPES, TableData } from '../../types';

type Props = {
  dateUnit: State['dateUnit'];
  data: TableData | undefined;
  visibleRows: (typeof TABLE_ROW_TYPES)[number][];
};

export const Table: React.FC<Props> = (props) => {
  if (props.data === undefined)
    return (
      <Cluster align="center" justify="center" height={320}>
        <LoadingCircle />
      </Cluster>
    );

  return <TableContainer {...props} data={props.data} />;
};

const TableContainer: React.FC<Props & { data: NonNullable<Props['data']> }> = (
  props,
) => {
  const periods = props.data.total.map(({ period }) => period);

  return (
    <div className={styles.scrollableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerRowUpper}>
            <th rowSpan={2} className={styles.firstColumn}>
              {/* spacer */}
            </th>
            {ITEM_TYPES.map((itemType) => (
              <th key={itemType} colSpan={periods.length}>
                {itemTypeToLabel(itemType)}
              </th>
            ))}
          </tr>
          <tr className={styles.headerRowLower}>
            {ITEM_TYPES.map((itemType) =>
              periods.map((period) => (
                <th key={`${itemType}-${period}`}>{period}</th>
              )),
            )}
          </tr>
        </thead>
        <tbody>
          {TABLE_ROW_TYPES.filter((t) => props.visibleRows.includes(t)).map(
            (facilityType) => (
              <tr
                key={facilityType}
                className={
                  facilityType === 'total' ? styles.totalRow : styles.bodyRow
                }
              >
                <th className={styles.firstColumn}>
                  {facilityTypeToLabel(facilityType)}
                </th>
                {ITEM_TYPES.map((itemType) =>
                  periods.map((period, i) => {
                    const value = props.data[facilityType][i][itemType];

                    return isNaN(value) ? (
                      <td>-</td>
                    ) : (
                      <td>
                        {value.toLocaleString()}
                        {itemTypeToPostfix(itemType)}
                      </td>
                    );
                  }),
                )}
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
};
