import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { useGetFacilityTypeGroups } from '@/src/hooks/fetcher/useGetFacilityTypeGroups';
import { useGetMedicalInstitutionLabels } from '@/src/hooks/fetcher/useGetMedicalInstitutionLabels';
import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useReferralReportAggregationResults } from '@/src/hooks/fetcher/useReferralReportAggregationResults';
import { useScrollContainerWidth } from '@/src/hooks/useScrollContainerWidth';

import { useAction } from './action';
import { ChartWithControl } from './components/ChartWithControl';
import { FilterForm } from './components/FilterForm';
import { Table } from './components/Table';
import { toRequestParameter } from './mapper';
import { actionType, useSummaryState } from './reducer';
import {
  facilityTypeFiltersToVisibleTableRows,
  toTableData,
  toChartData,
} from './selector';

import { useMemo } from 'react';

const MIN_CONTENT_WIDTH = 1250;
const MAIN_CONTENT_HORIZONTAL_PADDING = 80;

export const Summary: React.FC = () => {
  const { state, dispatch } = useSummaryState();
  const { scrollContainerWidth } = useScrollContainerWidth();
  const { csvDownload } = useAction();

  // 絞り込みモーダル表示時に待たされないように事前にデータをキャッシュしておく
  useGetFacilityTypeGroups();
  useGetOwnDepartments();
  useGetMedicalInstitutionLabels();

  const response = useReferralReportAggregationResults({
    postReferralReportAggregationResultsRequest: toRequestParameter(
      state.filters,
      state.dateUnit,
    ),
  });

  const tableData = toTableData(response.data?.results, state.dateUnit);
  const chartData = useMemo(
    () => toChartData(response.data?.results, state.dateUnit),
    [response.data],
  );

  return (
    <Stack align="stretch" gap={24} width="initial">
      <FilterForm
        filters={state.filters}
        setFilters={(filters) =>
          dispatch({ type: actionType.setFilters, payload: filters })
        }
        onClickCsvExport={() =>
          tableData
            ? csvDownload(
                'サマリー',
                tableData,
                facilityTypeFiltersToVisibleTableRows(
                  state.filters.facilityTypeIds,
                ),
              )
            : undefined
        }
      />
      <Card>
        <Stack
          align="stretch"
          gap={0}
          width={
            Math.max(scrollContainerWidth, MIN_CONTENT_WIDTH) -
            MAIN_CONTENT_HORIZONTAL_PADDING
          }
        >
          <ChartWithControl
            dateUnit={state.dateUnit}
            setDateUnit={(dateUnit) =>
              dispatch({ type: actionType.setDateUnit, payload: dateUnit })
            }
            data={chartData}
          />
          <Table
            dateUnit={state.dateUnit}
            data={tableData}
            visibleRows={facilityTypeFiltersToVisibleTableRows(
              state.filters.facilityTypeIds,
            )}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
