import {
  MedicalInstitutionRunningStatusEnum,
  NullableMedicalInstitutionRunningStatusEnum,
} from '../api/generated';
import { QueryRouterOutputs } from '../api/trpc/client';

/**
 * RunningStatusEnumを文字列表現に変換する
 */
// eslint-disable-next-line complexity
export const toString = (
  status:
    | MedicalInstitutionRunningStatusEnum
    | NullableMedicalInstitutionRunningStatusEnum
    | QueryRouterOutputs['medicalInstitution']['getById']['runningStatus']
    | undefined
    | null,
): string => {
  switch (status) {
    case undefined:
    case null:
    case MedicalInstitutionRunningStatusEnum.Open:
    case NullableMedicalInstitutionRunningStatusEnum.Open:
    case 'open':
      return '';
    case MedicalInstitutionRunningStatusEnum.Closed:
    case NullableMedicalInstitutionRunningStatusEnum.Closed:
    case 'closed':
      return '閉院';
    case MedicalInstitutionRunningStatusEnum.Suspended:
    case NullableMedicalInstitutionRunningStatusEnum.Suspended:
    case 'suspended':
      return '休止';
    case MedicalInstitutionRunningStatusEnum.Unknown:
    case NullableMedicalInstitutionRunningStatusEnum.Unknown:
    case 'unknown':
      return '不明';
  }
};

/**
 * RunningStatusEnumを画面表示用フォーマットへ変換する
 */
export const toDisplayFormat = (
  status:
    | MedicalInstitutionRunningStatusEnum
    | NullableMedicalInstitutionRunningStatusEnum
    | QueryRouterOutputs['medicalInstitution']['getById']['runningStatus']
    | undefined
    | null,
): string => {
  const label = toString(status);

  return label ? `【${label}】` : '';
};
