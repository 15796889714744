import { referralReportsApi } from '@/src/api';
import {
  PostReferralReportAggregationResultsOperationRequest,
  PostReferralReportAggregationResultsResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch, AsyncFetchResponse } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useReferralReportAggregationResults = (
  params: PostReferralReportAggregationResultsOperationRequest,
): AsyncFetchResponse<PostReferralReportAggregationResultsResponse> => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey(
      'referralReportsApi',
      'postReferralReportAggregationResults',
      params,
    ),
    () => referralReportsApi.postReferralReportAggregationResults(params),
    // 3回までリトライしてだめならエラーを投げる。
    // ただし、429以外の400番台のエラーはリトライする必要がないので即時エラーにする
    (err, key, config, revalidate, { retryCount }) => {
      if (retryCount > 3) throwFetchError(err);
      if (
        err.statusCode >= 400 &&
        err.statusCode < 500 &&
        err.statusCode !== 429
      )
        throwFetchError(err);

      setTimeout(
        () => revalidate({ retryCount }),
        1000 * 2 ** (retryCount - 1),
      );
    },
    {
      // 表示中のデータを勝手に再検証してほしくないが、
      // コンポーネントマウント時はデータの更新があるかもしれないので再検証してほしい
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    },
  );

  return response;
};
