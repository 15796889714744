import { ReferralReportAggregationResultRow } from '@/src/api/generated';
import { HalfYear } from '@/src/types/date';
import { toHalfYearLabel } from '@/src/utils/date';

import { State } from './reducer';
import { ChartData, TABLE_ROW_TYPES, ITEM_TYPES, TableData } from './types';

import groupBy from 'lodash.groupby';

// hospitalとclinicはmisのfacility_typesのIDに合致する
const ROW_VALUE_HOSPITAL = '1';
const ROW_VALUE_CLINIC = '2';
const ROW_VALUE_TOTAL = 'total';

export const toChartData = (
  source: ReferralReportAggregationResultRow[] | undefined,
  dateUnit: State['dateUnit'],
): ChartData | undefined => {
  if (source === undefined) return undefined;

  return source
    .filter((r) => r.groupingKeys[1] === 'total') // 施設タイプの総計のみを抽出
    .filter((r) => r.groupingKeys[0] !== 'total') // 期間の総計は除外
    .sort((a, b) => a.groupingKeys[0].localeCompare(b.groupingKeys[0])) // 期間の昇順にソート
    .map((r): ChartData[number] => {
      return {
        period: formatLabel(r.groupingKeys[0], dateUnit),
        referralCount: r.referralCount ?? 0,
        referralAdmissionCount: r.hospitalAdmissionCount ?? 0,
        referralOperationCount: r.operationCount ?? 0,
        referralAdmissionRate: Math.round(
          ((r.hospitalAdmissionCount ?? NaN) / (r.referralCount ?? NaN)) * 100,
        ),
        referralOperationRate: Math.round(
          ((r.operationCount ?? NaN) / (r.referralCount ?? NaN)) * 100,
        ),
      };
    });
};

/* eslint-disable no-irregular-whitespace */
/**
 * レポート集計データをテーブルデータに変換する。以下のようなルールでレポート集計データの変換を行っている
 *
 * [
 *   // grouping_key[0] は期間（年の場合"2024"、半年の場合"2024H1"、月の場合"2024-04"など）
 *   // grouping_key[1] = 1 の場合、"hospital"にマッピング（紹介入院率、紹介手術率は計算して算出）
 *   { "grouping_keys": [  <n>, "1"], referralCount:  127, referralAdmissionCount:   27, referralOperationCount:   8, referralAdmissionRate: 21, referralOperationRate: 6 },
 *   { "grouping_keys": [<n+1>, "1"], referralCount:  277, referralAdmissionCount:   75, referralOperationCount:  26, referralAdmissionRate: 27, referralOperationRate: 9 },
 *
 *   // grouping_key[1] = 2 の場合、"clinic"にマッピング
 *   { "grouping_keys": [ <n>, "2"], referralCount: 4972, referralAdmissionCount: 1191, referralOperationCount: 403, referralAdmissionRate: 24, referralOperationRate: 8 },
 *
 *   // grouping_key[1] が "1", "2", "total" 以外の場合、値を合算して"others"にマッピング
 *   { "grouping_keys": [  <n>,    "3"], referralCount: 0, referralAdmissionCount: 0, referralOperationCount: 0, referralAdmissionRate: 0, referralOperationRate: 0 },
 *   { "grouping_keys": [  <n>, "3001"], referralCount: 0, referralAdmissionCount: 0, referralOperationCount: 0, referralAdmissionRate: 0, referralOperationRate: 0 },
 *   ...
 *
 *   // grouping_key[1] = "total" の場合、"total"にマッピング
 *   { "grouping_keys": [  <n>, "total"], referralCount: 0, referralAdmissionCount: 0, referralOperationCount: 0, referralAdmissionRate: 0, referralOperationRate: 0 },
 * ]
 *   ↓↓↓↓↓
 * {
 *   hospital: [
 *     { period:   <n>, referralCount:  127, referralAdmissionCount:   27, referralOperationCount:   8, referralAdmissionRate: 21, referralOperationRate: 6 },
 *     { period: <n+1>, referralCount:  277, referralAdmissionCount:   75, referralOperationCount:  26, referralAdmissionRate: 27, referralOperationRate: 9 },
 *     ...
 *   ],
 *   clinic: [
 *     { period:   <n>, referralCount: 4972, referralAdmissionCount: 1191, referralOperationCount: 403, referralAdmissionRate: 24, referralOperationRate: 8 },
 *     ...
 *   ],
 *   others: [...],
 *   total: [...]
 * }
 */
/* eslint-enable no-irregular-whitespace */
export const toTableData = (
  source: ReferralReportAggregationResultRow[] | undefined,
  dateUnit: State['dateUnit'],
): TableData | undefined => {
  if (source === undefined) return undefined;

  // 初手施設タイプでグルーピングすると期間が歯抜けになるため、先に期間でグルーピングして歯抜けをなくしている（こちらの歯抜けはない仕組み）
  const groupByPeriod = groupBy(source, (r) => r.groupingKeys[0]);
  const groupByPeriodFacilityTypes = Object.keys(groupByPeriod)
    .filter((period) => period !== 'total')
    .sort((a, b) => a.localeCompare(b)) // 期間の昇順にソート
    .map((period) =>
      groupByPeriod[period].reduce<{
        period: string;
        hospital: ReferralReportAggregationResultRow[];
        clinic: ReferralReportAggregationResultRow[];
        others: ReferralReportAggregationResultRow[];
        total: ReferralReportAggregationResultRow[];
      }>(
        (acc, r) => {
          switch (r.groupingKeys[1]) {
            case ROW_VALUE_HOSPITAL:
              return { ...acc, hospital: [...acc.hospital, r] };
            case ROW_VALUE_CLINIC:
              return { ...acc, clinic: [...acc.clinic, r] };
            case ROW_VALUE_TOTAL:
              return { ...acc, total: [...acc.total, r] };
            default:
              return { ...acc, others: [...acc.others, r] };
          }
        },
        { period, hospital: [], clinic: [], others: [], total: [] },
      ),
    );

  return {
    hospital: groupByPeriodFacilityTypes.map(({ period, hospital }) =>
      toTableRow(period, hospital, dateUnit),
    ),
    clinic: groupByPeriodFacilityTypes.map(({ period, clinic }) =>
      toTableRow(period, clinic, dateUnit),
    ),
    others: groupByPeriodFacilityTypes.map(({ period, others }) =>
      toTableRow(period, others, dateUnit),
    ),
    total: groupByPeriodFacilityTypes.map(({ period, total }) =>
      toTableRow(period, total, dateUnit),
    ),
  };
};

const toTableRow = (
  period: string,
  rows: ReferralReportAggregationResultRow[],
  dateUnit: State['dateUnit'],
): TableData['hospital' | 'clinic' | 'others' | 'total'][number] => {
  const referralCount = rows.reduce(
    (acc, r): number => acc + (r.referralCount ?? 0),
    0,
  );
  const referralAdmissionCount = rows.reduce(
    (acc, r): number => acc + (r.hospitalAdmissionCount ?? 0),
    0,
  );
  const referralOperationCount = rows.reduce(
    (acc, r): number => acc + (r.operationCount ?? 0),
    0,
  );

  return {
    period: formatLabel(period, dateUnit),
    referralCount,
    referralAdmissionCount,
    referralOperationCount,
    referralAdmissionRate: Math.round(
      (referralAdmissionCount / referralCount) * 100,
    ),
    referralOperationRate: Math.round(
      (referralOperationCount / referralCount) * 100,
    ),
  };
};

const formatLabel = (v: string, dateUnit: State['dateUnit']): string => {
  switch (dateUnit) {
    case 'year':
      return toYearLabel(v);
    case 'halfYear':
      return toHalfYearLabel(v as HalfYear);
    case 'month':
      return v; // yyyy-MM
  }
};

const toYearLabel = (year: string): string => `${year}年度`;

export const facilityTypeToLabel = (
  facilityType: (typeof TABLE_ROW_TYPES)[number],
): string => {
  switch (facilityType) {
    case 'hospital':
      return '病院';
    case 'clinic':
      return 'クリニック';
    case 'others':
      return 'その他';
    case 'total':
      return '総計';
  }
};

export const itemTypeToPostfix = (
  itemType: (typeof ITEM_TYPES)[number],
): string => {
  switch (itemType) {
    case 'referralAdmissionRate':
    case 'referralOperationRate':
      return '%';
    default:
      return '';
  }
};

export const itemTypeToLabel = (
  itemType: (typeof ITEM_TYPES)[number],
): string => {
  switch (itemType) {
    case 'referralCount':
      return '紹介数';
    case 'referralAdmissionCount':
      return '紹介入院数';
    case 'referralOperationCount':
      return '紹介手術数';
    case 'referralAdmissionRate':
      return '紹介入院率';
    case 'referralOperationRate':
      return '紹介手術率';
  }
};

export const facilityTypeFiltersToVisibleTableRows = (
  facilityTypeFilters: State['filters']['facilityTypeIds'],
): (typeof TABLE_ROW_TYPES)[number][] => {
  if (facilityTypeFilters.length === 0) return [...TABLE_ROW_TYPES]; // readonly回避のためにスプレッド演算子を使用

  return [
    ...(facilityTypeFilters.includes(Number(ROW_VALUE_HOSPITAL))
      ? (['hospital'] as const)
      : []),
    ...(facilityTypeFilters.includes(Number(ROW_VALUE_CLINIC))
      ? (['clinic'] as const)
      : []),
    ...(facilityTypeFilters.some(
      (t) => t !== Number(ROW_VALUE_HOSPITAL) && t !== Number(ROW_VALUE_CLINIC),
    )
      ? (['others'] as const)
      : []),
    'total',
  ];
};
